import { Flex } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { StyledSelect } from '@tasklogy/zircon-ui-components';

import { useCallback, useMemo } from 'react';
import {
    selectComponentById,
    selectConnectedSources
} from 'src/redux/features/blueprint/bluePrintSlice';
import FormTitle from '../../layout/FormTitle';
import Control from '../SetupForm/Control';
import Option from '../SetupForm/Option';
import SelectDimensions, { DimensionValue } from '../SetupForm/SelectDimensions';
import { DimensionFilter } from 'common/types';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { getWholeConfigs } from '../SetupForm/utils';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';

const FilterForm = () => {
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();

    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    ) as DimensionFilter;
    const connectedSources = useAppSelector(selectConnectedSources);
    const connectedSourceOptions = useMemo(() => {
        return connectedSources?.map((cs) => ({
            value: cs,
            label: cs?.advertiser?.advertiserName
        }));
    }, [connectedSources]);

    const connectedSource = connectedSources?.find(
        (ds) => ds.id === componentToEdit.connectedSourceId
    );
    const dataSourceDiemnsions = connectedSource?.dataSource?.dimensions;

    const memoizedValues = useMemo(() => {
        // Calculate selected metrics

        // Calculate selected dimensions
        const selectedDimensions = componentToEdit.dimensionId
            ? getWholeConfigs([{ id: componentToEdit.dimensionId }], dataSourceDiemnsions)
            : [];

        return {
            selectedDimensions,
            dimensionOptions: dataSourceDiemnsions
        };
    }, [componentToEdit, dataSourceDiemnsions]);

    const { selectedDimensions, dimensionOptions } = memoizedValues;

    const setComponentConfig = useSetComponentConfig();

    const handleDimensionsChange = useCallback(
        (values: DimensionValue[]) => {
            const dimensions = values.map((option) => ({
                id: option.id,
                customDisplayName: option.customDisplayName ?? null,
                customDecimalPlaces: option.customDecimalPlaces ?? null
            }));

            setComponentConfig({
                ...componentToEdit,
                dimensionId: dimensions[0]?.id
            });
        },
        [componentToEdit, setComponentConfig]
    );

    return (
        <Flex flexDir="column">
            <Flex mt={4} flexDir="column">
                <FormTitle title="Select Advertiser Account" />
                <StyledSelect
                    isMulti={false}
                    onChange={async (target: any) => {
                        const updatedComponent = {
                            ...componentToEdit,
                            dimensions: [],
                            metrics: [],
                            connectedSourceId: target.value.id
                        };

                        setComponentConfig(updatedComponent);
                    }}
                    options={connectedSourceOptions}
                    value={connectedSourceOptions?.find(
                        (option) => option.value.id === connectedSource?.id
                    )}
                    components={{ Option, Control }}
                />
            </Flex>

            <SelectDimensions
                selectedDimensions={selectedDimensions}
                dimensionOptions={dimensionOptions}
                onChange={handleDimensionsChange}
                isSingle
            />
        </Flex>
    );
};

export default FilterForm;
